import { React, useState } from 'react';
import { Button, Divider, Space, Radio } from 'antd-mobile';
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const CalendarApi = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})


    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleCalendar = () => {
        let data = {
            "action": "add",                   // 行为，目前支持 add 和 remove，默认为 add
            "events": [
                {
                    "title": "【还呗】账单还款提醒",      // 标题
                    "startDate": "20240818 08:30",     // 开始时间，需要满足 "yyyyMMdd HH:mm" 格式
                    "endDate": "20240818 10:30",       // 结束时间，需要满足 "yyyyMMdd HH:mm" 格式
                    "recurrence": "monthly",           // 目前只支持按月重复，如果传空就不重复
                    "daysOfTheMonth": [10, 11],        // 一个月中哪天重复，当 recurrence 为 monthly 才有效。如果 recurrence 为 monthly 这个字段没有值，使用开始时间中的日重复
                    "recurrenceEndDate": "20251018",   // 重复的结束日期，需要满足 "yyyyMMdd" 格式。当 recurrence 为 monthly 必须传这个字段，否则不重复
                    "alarmOffset": 3,                  // 日程开始前多长时间提醒时间，单位为分钟。如果没有这个字段或者为负数不提醒。如果传0表示日程开始时提醒
                    "notes": "明天是您的还款日，系统将于上午 7 ：00 和下午 18:00 左右自动扣款，请确保还款卡内余额充足（已还请忽略），可进入还呗App查账还款，立即查看http://lb0.cc/39GcFP" // 备注
                }
            ]
        }


        // let data = {
        //     "action": "remove",                   // 行为，目前支持 add 和 remove，默认为 add
        //     "events": [
        //         {
        //             "eventIdentifier": "28"
        //         }
        //     ]
        // }
        setApiParams(data)
        fcs.openModule('calendar', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>Calendar</Divider>
            <Button color='primary' onClick={handleCalendar}>日历功能</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default CalendarApi