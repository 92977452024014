import { React, useState } from 'react'
import { Button, Divider, Space, Input } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import { device } from '../utils/device'

const ShowToast = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [value, setValue] = useState('请输入Toast文案')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleShowToast = () => {
        if (device.dmlife) {
            setApiParams(value)
            fcs.invoke('showToast', value, (resp) => {
                handleNormalCallback(resp)
            })
        } else {
            let data = {message: value}
            setApiParams(value)
            fcs.invoke('showToast', data, (resp) => {
                handleNormalCallback(resp)
            })
        }
    }
    return (
        <div>
            <Divider>showToast</Divider>
            <Input style={{ margin: "10px" ,}}
                placeholder='请输入Toast文案'
                value={value}
                onChange={val => {
                    setValue(val)
                }}
            />
            <Button color='primary' onClick={handleShowToast}>展示Toast</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default ShowToast