import { React, useState } from 'react';
import { Button, Divider, Space, Radio } from 'antd-mobile';
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const UploadImage = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})


    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            "imageList":[
                {
                    "localId":"hblocalsource://storage/1718851488888",
                    "order":0
                }
            ],
            "extraParams":{
                "uid":"xxx",
                "behaviorId":"xxx",
                "orderNo":"xxx"
            }
        }
        
        setApiParams(data)
        fcs.openModule('uploadImage', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>UploadImage</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>上传图片</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default UploadImage