import { device } from './device'
import Toast from 'antd-mobile/es/components/toast'

export function Functions() {
    const fcs = {}
    const invoke = (name, params, callback) => {
        if (device.dmlife && window.dmlife) {
            let fc = window.dmlife[name]
            if (fc) {
                if (Object.keys(params).length === 0) {
                    fc.apply(null, [callback])
                } else {
                    fc.apply(null, [params, callback])
                }
            } else {
                Toast.show({
                    content: 'dmlife API 不存在'
                })
            }

        } else if (device.mpaas && window.AlipayJSBridge) {
            window.AlipayJSBridge.call(name, params, callback)
        } else {
            // nothing to do
        }
    }
    const openModule = (name, params, callback, options) => {
        if (device.dmlife && window.dmlife) {
            window.dmlife.openModule(name, params, callback, options)
        } else if (device.mpaas && window.AlipayJSBridge) {
            window.AlipayJSBridge.call('openModule', {name, params, options}, callback)
        } else {
            // nothing to do
        }
    }

    fcs.invoke = invoke
    fcs.openModule = openModule
    return fcs
}
export const fcs = Functions()
export default fcs
