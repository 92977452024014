import { React, useState } from 'react'
import { Button, Divider, Space, Radio } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const DataCollect = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [currentEvent, setCurrentEvent] = useState('loan')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleEventChange = (e) => {
        setCurrentEvent(e)
    }

    const handleDataCollect = () => {
        let params = {
            event: currentEvent,
            traceid: Math.random().toString(36).substring(2)
        }
        setApiParams(params)
        fcs.openModule('DATA_COLLECT', params, (resp) => {
            handleNormalCallback(resp)
        })
    }
    return (
        <div>
            <Divider>dataCollect</Divider>
            <p>权限类型选择</p>
            <Radio.Group onChange={handleEventChange} value={currentEvent}>
                <Space direction='vertical' style={{ margin: '10px' }} block>
                    <Radio block value={'loan'}>loan</Radio>
                    <Radio block value={'appStart'}>appStart</Radio>
                    <Radio block value={'login'}>login</Radio>
                    <Radio block value={'setLoginPassword'}>setLoginPassword</Radio>
                    <Radio block value={'setDealPassword'}>setDealPassword</Radio>
                    <Radio block value={'repayment'}>repayment</Radio>
                    <Radio block value={'userInfoEntryOpen'}>userInfoEntryOpen</Radio>
                    <Radio block value={'userInfoEntry'}>userInfoEntry</Radio>
                    <Radio block value={'userInfoEntryContacts'}>userInfoEntryContacts</Radio>
                    <Radio block value={'tryLend'}>userInfoEntryContacts</Radio>
                    <Radio block value={'contacts'}>contacts</Radio>
                </Space>
            </Radio.Group>
            <Button color='primary' onClick={handleDataCollect}>数据采集</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default DataCollect