import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import { device } from '../utils/device'

const AppStorage = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBool = (method) => {
        let key = 'test_bool'
        let value = {
            method: method,
            key: key,
            value: method === 'SET' ? false : undefined,
            validityTimeInterval: 3600
        }
        let name = 'appStorage'
        if (device.dmlife) {
            name = method === 'GET' ? 'webStorageLoad' : 'webStorageSave'
            value = method === 'SET' ? false : key
        }
        setApiParams(value)
        if (device.dmlife && method === 'SET') {
            fcs.invoke(name, key, value)
            return
        }
        fcs.invoke(name, value, (resp) => {
            if (resp) {
                handleNormalCallback(resp)
            }
        })
    }

    const handleString = (method) => {
        let key = 'test_string'
        let name = 'appStorage'
        let value = {
            method: method,
            key: key,
            value: method === 'SET' ? '存的是字符串' : undefined,
            validityTimeInterval: 3600
        }
        if (device.dmlife) {
            name = method === 'GET' ? 'webStorageLoad' : 'webStorageSave'
            value = method === 'SET' ? '存的是字符串2' : key

        }
        if (device.dmlife && method === 'SET') {
            setApiParams({ key, value })
            fcs.invoke(name, key, value)
            return
        }
        setApiParams(value)
        fcs.invoke(name, value, (resp) => {
            if (resp) {
                handleNormalCallback(resp)
            }
        })
    }

    const handleObj = (method) => {
        let key = 'test_obj'
        let value = {
            method: method,
            key: key,
            value: method === 'SET' ? { 'tt': 'aa', 'cc': 'vv' } : undefined,
            validityTimeInterval: 3600
        }
        let name = 'appStorage'
        if (device.dmlife) {
            name = method === 'GET' ? 'webStorageLoad' : 'webStorageSave'
            value = method === 'SET' ? { 'tt': 'aa', 'cc': 'vv' } : key
        }
        if (device.dmlife && method === 'SET') {
            setApiParams({ key, value })
            fcs.invoke(name, key, value)
            return
        }
        setApiParams(value)
        fcs.invoke(name, value, (resp) => {
            if (resp) {
                handleNormalCallback(resp)
            }
        })

    }

    return (
        <div>
            <Divider>布尔值存储</Divider>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleBool('SET') }}>存</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleBool('GET') }}>取</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleBool('REMOVE') }}>删</Button>
            <Divider>字符串存储</Divider>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleString('SET') }}>存</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleString('GET') }}>取</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleString('REMOVE') }}>删</Button>
            <Divider>对象存储</Divider>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleObj('SET') }}>存</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleObj('GET') }}>取</Button>
            <Button style={{ margin: '0 5px' }} color='primary' onClick={() => { handleObj('REMOVE') }}>删</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default AppStorage