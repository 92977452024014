import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import userEvent from '@testing-library/user-event'

/**
 * 获取联系人
 */
const IdCardIdentification = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})
    const [idFrontThumbnail, setIdFrontThumbnail] = useState('')
    const [idBackThumbnail, setIdBackThumbnail] = useState('')

    const handleNormalCallback = (resp) => {
        if (resp && resp.data) {
            setIdFrontThumbnail(resp.data.idFrontThumbnail)
            setIdBackThumbnail(resp.data.idBackThumbnail)

            if (resp.data.idFrontThumbnail) {
                resp.data.idFrontThumbnail = '见上方缩略图'
            }
            if (resp.data.idBackThumbnail) {
                resp.data.idBackThumbnail = '见上方缩略图'
            }
        }
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            'currentSupplier': 'MEGVII', // MEGVII_ALBUM
            'idCardSide': 1, // 正面：1，反面：2，正反面：3
            'tcSdkParamsUrl': '',
            'megviiSdkParamsUrl': '',
            'permission': {
                "trackName": "权限埋点",
                "isRequired": true, // 是否必要
                "isDetain": true,   // 是否挽留，默认为true
                "detainMessage": "这是挽留弹窗的话术-仅Android端使用",
                "guideToSettingMessage": "这是引导去设置的话术-Android和iOS共用",
                "dialogStyle": "blue", // blue-蓝色样式 orange-橙色样式,
                "guideToSettingPositiveText": "去授权", // 确定按钮文字
                "guideToSettingNegativeText": "取消" // 取消按钮文字
            },
            'scene': 'APPLY',
            'process': 'IDENTIFICATION_CARD',
            'idApiUploadUrl': '',
            'usedScene': '使用场景',
            'albumSelectTip': '请选择身份证xx面',
            'albumMaxSize': 10,
            'albumNewStyle': false,
            'trackName': '组件埋点前缀',
            'nfcIdInfoUrl': '',
        }
        setApiParams(data)
        fcs.openModule('idCardIdentification', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>IdCardIdentification</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>身份证识别</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <img src={'data:image/png;base64,' + idFrontThumbnail} width='160' height='120' />
                <img src={'data:image/png;base64,' + idBackThumbnail} width='160' height='120' />
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default IdCardIdentification