import { React, useRef, useState } from 'react';
import { UploadOutlined, DownOutlined } from '@ant-design/icons';
import { Flex, Button, Upload, Tree, Divider, Card, Checkbox, message, Space, Input } from 'antd';

const MyTree = () => {

  const [fileList, setFileList] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [repeatRecords, setRepeatRecords] = useState([]);
  const [shieldFullTrackEvent, setShieldFullTrackEvent] = useState(false);
  const [showGlobalParam, setShowGlobalParam] = useState(false);
  const [showCmsGlobalParam, setShowCmsGlobalParam] = useState(false);
  const [polymerizationFullTrackEvent, setPolymerizationFullTrackEvent] = useState(false);
  const [delegateUrlValue, setDelegateUrlValue] = useState('http://localhost:8080');

  const fullEventList = useRef([]);
  const normalEventList = useRef([]);
  const errorEventList = useRef([]);
  const recordOriginData = useRef(undefined);

  const shieldFullTrack = useRef(false);
  const showGlobal = useRef(false);
  const showCmsGlobal = useRef(false);
  const polymerizationFullTrack = useRef(false);

  const globalParamKey = [
    'evt_id', 'uid', 'cjjid', 'page_id', 'page_name', 'evt_obj_id', 'evt_hier', 'proc_lif_id', 'corr_id', 'user_flw_id',
    'act_id', 'view_id', 'prev_page_id', 'prev_view_id', 'evt_time', 'upload_time', 'glb_lif_evt_cnt', 'lif_evt_no', 'app_identifier', 'req_chan',
    'app_chan', 'app_ver', 'inner_ver', 'opv_cmn', 'opv_apply', 'opv_credit', 'opv_biz', 'os_ty', 'os_ver', 'sdk_ty',
    'battery', 'network', 'long', 'lat', 'alt', 'lbs_gath_time', 'behavior'
  ]

  const globalCmsParamKey = [
    'cms_page_id', 'res_id', 'comp_id', 'ele_id', 'cms_page_name', 'order_id', 'cms_id', 'point_n', 'layout_code', 'layout_version', 'exp_n',
    'exp_id', 'ep_gp', 'gray_name', 'gray_code', 'sh_res', 'ug_1_name', 'ug_1_id'
  ]

  const beforeUpload = (file) => {
    setFileList([file]);
    const reader = new FileReader()
    reader.onload = (e) => {
      let result = JSON.parse(e.target.result)
      handleNode(result)
    }
    reader.readAsText(file)
    return false
  }

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const handleNode = (result) => {
    let defaultArr = []
    let normalArr = []
    let errorArr = []
    let repeatArr = []
    let eventIdSet = new Set()
    // $ 开头的事件
    // 正常的事件
    for (let index = 0; index < result.length; index++) {
      let node = { key: '', title: '', children: [], record: {}, error: false, errorMessage: '' }
      const element = result[index];
      let beforeSize = eventIdSet.size;
      eventIdSet.add(element.evt_id)
      let endSize = eventIdSet.size
      if (beforeSize === endSize) {
        repeatArr.push(element.evt_id)
        node.error = true
        node.errorMessage = '事件重复'
      }

      if (element.behavior && element.behavior.length > 0) {
        if (element.behavior.substr(0, 1) === '$') {
          node.key = element.evt_id
          node.title = element.behavior + ' ## ' + element.evt_id
          node.record = element
          defaultArr.push(node)
        } else {
          node.key = element.evt_id
          node.title = newTitle(element) + ' ## ' + element.evt_id
          node.record = element
          let checkStatus = checkEvent(element)
          if (checkStatus.length > 0) {
            node.error = true
            node.errorMessage = checkStatus + ' + ' + node.errorMessage
          }
          normalArr.push(node)
        }
      } else {
        node.key = element.evt_id
        node.title = newTitle(element) + ' ## ' + element.evt_id
        node.record = element
        node.error = true
        node.errorMessage = '缺少 behavior 字段' + ' + ' + node.errorMessage
        errorArr.push(node)
      }
    }
    let newArr = []
    for (let index = 0; index < normalArr.length; index++) {
      const item1 = normalArr[index];
      var contain = false
      for (let idx = 0; idx < normalArr.length; idx++) {
        const item2 = normalArr[idx];
        if (item1.record.sh_res === item2.record.evt_id) {
          contain = true
          if (!item2.children.includes(item1)) {
            item2.children.push(item1)
          }
        }
      }
      if (!contain && !newArr.includes(item1)) {
        newArr.push(item1)
      }
    }
    fullEventList.current = defaultArr
    normalEventList.current = newArr
    errorEventList.current = errorArr
    setRepeatRecords(repeatArr)
    uptateEventListShow()
  }
  const checkEvent = (record) => {
    if (record) {
      if (record.evt_hier === 'element') {
        if (record.ele_id && record.res_id && record.comp_id) {

        } else {
          return "CMS字段缺失"
        }
      } else if (record.evt_hier === 'component') {
        if (record.res_id && record.comp_id) {

        } else {
          return "CMS字段缺失"
        }
      } else if (record.evt_hier === 'resource') {
        if (record.res_id) {
        } else {
          return "CMS字段缺失"
        }
      } else if (!record.evt_hier) {
        return "CMS字段缺失"
      }
    }
    return ''
  }

  const uptateEventListShow = () => {
    let nodeArr = [...normalEventList.current]
    let fullTrackList = fullEventList.current

    if (polymerizationFullTrack.current === true) {
      fullTrackList = handleFullTrackEvent(fullTrackList)
    } else {
      fullTrackList.map(item => {
        item.children = []
        return item
      })
    }

    if (shieldFullTrack.current === false) {
      nodeArr = nodeArr.concat(fullTrackList)
    }
    nodeArr = nodeArr.concat(errorEventList.current)
    setTreeData(nodeArr)
    updateRecordShow()
  }

  const handleFullTrackEvent = (fullTrackList) => {
    if (fullTrackList) {
      let round = Math.round(Math.random() * 1000000);
      let tempMap = new Map()
      for (let index = 0; index < fullTrackList.length; index++) {
        const element = fullTrackList[index];
        let behavior = element.record.behavior
        if (Object.keys(tempMap).includes(behavior)) {
          let record = tempMap[behavior]
          element.key = element.record.evt_id + '-' + round.toString()
          record.children.push(Object.assign({}, element))
        } else {
          element.key = element.record.evt_id + '-' + round.toString()
          element.children = []
          tempMap[behavior] = Object.assign({}, element)
        }
      }
      return Object.values(tempMap)
    }
    return []
  }

  const updateRecordShow = () => {
    if (recordOriginData.current) {
      let record = { title: '', formatStrings: [] }
      let newRecord = Object.assign({}, recordOriginData.current.record)
      record.title = recordOriginData.current.title
      if (showGlobal.current === true) {
        let globalParams = {}
        let noContainKeys = []
        for (let index = 0; index < globalParamKey.length; index++) {
          const element = globalParamKey[index];
          if (newRecord.hasOwnProperty(element)) {
            let value = newRecord[element]
            globalParams[element] = value
            delete newRecord[element]
          } else {
            noContainKeys.push(element)
          }
        }
        let formatString = JSON.stringify(globalParams, null, 2)
        let noContainString = JSON.stringify(noContainKeys, null, 2)
        record.formatStrings.push({
          title: '基础公参',
          params: formatString,
          noContainKeys: noContainString
        })
      }

      if (showCmsGlobal.current === true) {
        let globalParams = {}
        let noContainKeys = []
        for (let index = 0; index < globalCmsParamKey.length; index++) {
          const element = globalCmsParamKey[index];
          if (newRecord.hasOwnProperty(element)) {
            let value = newRecord[element]
            globalParams[element] = value
            delete newRecord[element]
          } else {
            noContainKeys.push(element)
          }
        }
        let formatString = JSON.stringify(globalParams, null, 2)
        let noContainString = JSON.stringify(noContainKeys, null, 2)
        record.formatStrings.push({
          title: 'CMS基础公参',
          params: formatString,
          noContainKeys: noContainString
        })
      }

      let formatString = JSON.stringify(newRecord, null, 2)
      record.formatStrings.push({
        title: '',
        params: formatString,
        noContainKeys: ''
      })
      record.formatStrings.reverse()
      setRecordData(record)
    }
  }

  const onSelect = (node, info) => {
    recordOriginData.current = info.node
    updateRecordShow()
  }

  const newTitle = (record) => {
    var title = ''
    if (record.page_id && record.page_id.length > 0) {
      title = title + record.page_id
    }

    if (record.res_id && record.res_id.length > 0) {
      title = title + '_' + record.res_id
    }

    if (record.comp_id && record.comp_id.length > 0) {
      title = title + '_' + record.comp_id
    }

    if (record.ele_id && record.ele_id.length > 0) {
      title = title + '_' + record.ele_id
    }

    if (record.behavior && record.behavior.length > 0) {
      title = title + '_' + record.behavior
    }
    return title
  }

  const shieldFullTrackEventChange = (e) => {
    setShieldFullTrackEvent(e.target.checked)
    shieldFullTrack.current = e.target.checked
    uptateEventListShow()
  }

  const polymerizationFullTrackEventChange = (e) => {
    setPolymerizationFullTrackEvent(e.target.checked)
    polymerizationFullTrack.current = e.target.checked
    uptateEventListShow()
  }

  const globalParamChange = (e) => {
    setShowGlobalParam(e.target.checked)
    showGlobal.current = e.target.checked
    uptateEventListShow()

  }
  const cmsGlobalParamChange = (e) => {
    setShowCmsGlobalParam(e.target.checked)
    showCmsGlobal.current = e.target.checked
    uptateEventListShow()
  }
  const handleLoadLocalData = () => {
    let headers = new Headers();
    headers.append('Content-Security-Policy', 'upgrade-insecure-requests');
    let request = new Request(delegateUrlValue + '/getTrack', {
      method: 'GET',
      headers: headers,
    });
    fetch(request)
      .then((response) => response.json())
      .then((data) => {
        handleNode(data.data)
      }).catch(err => {
        message.error('请输入正确的代理地址')
      });
  }

  const handleDeleteLocalData = () => {
    let headers = new Headers();
    headers.append('Content-Security-Policy', 'upgrade-insecure-requests');
    let request = new Request(delegateUrlValue + '/cleanTrack', {
      method: 'GET',
      headers: headers,
    });
    fetch(request)
      .then((response) => response.json())
      .then((data) => {
        message.info(data.message)
      }).catch(err => {
        message.error('请输入正确的代理地址')
      });
  }
  const handleDelegateUrlChange = (e) => {
    setDelegateUrlValue(e.target.value)
  }

  const formatRecord = () => {
    return <>
      {recordData.formatStrings && recordData.formatStrings.map((item, index) => {
        return (<Card title={item.title || ''} style={{ 'minWidth': '45%' }} key={`${item.title} + ${index}`}>
          <Flex gap="middle" justify='space-between'>
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: 13, wordBreak: 'break-word', textAlign: 'left', minWidth: '45%' }}>{item.params}</pre>
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: 13, wordBreak: 'break-word', textAlign: 'left', color: 'red', minWidth: '25%' }}>{item.noContainKeys}</pre>
          </Flex>
        </Card>)
      })}
    </>
  }

  return (
    <div>
      <Flex gap="middle" justify='center'>
        <Card>
          <Flex gap="middle" vertical justify='center'>
            <Space>
              <Button type='primary' onClick={handleLoadLocalData}>加载本地代理数据</Button>
              <Button type='primary' onClick={handleDeleteLocalData}>清除本地代理数据</Button>

            </Space>
            <Input defaultValue='http://localhost:8080' value={delegateUrlValue} onChange={handleDelegateUrlChange}></Input>
          </Flex>
        </Card>
        <Card>
          <Upload name='file' beforeUpload={beforeUpload} accept='.json' fileList={fileList} onRemove={onRemove} maxCount={1}>
            <Button type='primary' icon={<UploadOutlined />}>点击上传JSON日志</Button>
          </Upload>
        </Card>
        <Card>
          <Checkbox checked={shieldFullTrackEvent} onChange={shieldFullTrackEventChange} key={'屏蔽全埋点事件'}>屏蔽全埋点事件</Checkbox>
          <Checkbox checked={polymerizationFullTrackEvent} onChange={polymerizationFullTrackEventChange} key={'聚合全埋点事件'}>聚合全埋点事件</Checkbox>
          <Checkbox checked={showGlobalParam} onChange={globalParamChange} key={'基础公参独立展示'}>基础公参独立展示</Checkbox>
          <Checkbox checked={showCmsGlobalParam} onChange={cmsGlobalParamChange} key={'CMS公参独立展示'}>CMS公参独立展示</Checkbox>
        </Card>
      </Flex>
      <Divider>分割线</Divider>
      <Flex gap="middle" justify='center'>
        <Card style={{ 'minWidth': '45%' }} headStyle={{ color: repeatRecords.length > 0 ? 'red' : 'black' }}
          title={'事件列表' + (repeatRecords.length > 0 ? ' ' + repeatRecords.length + ' 条埋点事件重复' : '')}>
          <Tree showLine defaultExpandAll
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            onSelect={onSelect}
            titleRender={(node) => {
              if (node.error) {
                return <span className="ant-tree-title" style={{ 'color': 'red', height: '30px' }} >{node.title + " ## " + node.errorMessage}</span>
              } else {
                return <span className="ant-tree-title" style={{ height: '30px' }}>{node.title}</span>
              }
            }} />
        </Card>
        <Card title={recordData.title || '事件详情'} style={{ 'minWidth': '45%' }}>
          {formatRecord()}
        </Card>
      </Flex>
    </div>
  );
};

export default MyTree;