import { React, useState, useEffect } from 'react'
import { List } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { device } from '../utils/device'

const ApiTest = () => {
    const [apiList] = useState([
        {
            title: '退出拦截',
            apiName: 'backIntercept',
            path: '/backIntercept'
        },
        {
            title: '更新标题栏',
            apiName: 'updateNavBar',
            path: '/updateNavBar'
        },
        {
            title: '数据采集',
            apiName: 'dataCollect',
            path: '/dataCollect'
        },
        {
            title: '获取组件埋点开关',
            apiName: 'trackConfig',
            path: '/trackConfig'
        },
        {
            title: '表单提交',
            apiName: 'formHtml',
            path: '/formHtml'
        },
        {
            title: '获取推送权限',
            apiName: 'registerNotificationAuthorization',
            path: '/registerNotificationAuthorization'
        },
        {
            title: '拨打电话',
            apiName: 'OTOCall',
            path: '/OTOCall'
        },
        {
            title: '拦截页面关闭',
            apiName: 'interceptPageClose',
            path: '/interceptPageClose'
        },
        {
            title: '网络请求',
            apiName: 'httpRequest',
            path: '/httpRequest'
        },
        {
            title: '获取用户信息',
            apiName: 'getUserInfo',
            path: '/getUserInfo'
        },
        {
            title: '获取设备信息',
            apiName: 'getDeviceInfo',
            path: '/getDeviceInfo'
        },
        {
            title: '获取移动端配置',
            apiName: 'clientConfig',
            path: '/clientConfig'
        },
        {
            title: '银行卡识别',
            apiName: 'bankCardDetect',
            path: '/bankCardDetect'
        },
        {
            title: 'LocalId网络请求',
            apiName: 'localIdHttpRequest',
            path: '/localIdHttpRequest'
        },
        {
            title: '身份证提交',
            apiName: 'IDSubmitHttpRequest',
            path: '/IDSubmitHttpRequest'
        },
        {
            title: '身份证识别',
            apiName: 'idCardIdentification',
            path: '/idCardIdentification'
        },
        {
            title: '事件通知',
            apiName: 'appNotify',
            path: '/appNotify'
        },
        {
            title: '校验手机号',
            apiName: 'verifyCurrentMobile',
            path: '/verifyCurrentMobile'
        },
        {
            title: '系统设置页',
            apiName: 'gotoApplicationSettings',
            path: '/gotoApplicationSettings'
        },
        {
            title: '获取权限状态',
            apiName: 'permissionStatus',
            path: '/permissionStatus'
        },
        {
            title: '展示Toast',
            apiName: 'showToast',
            path: '/showToast'
        },
        {
            title: '展示Loading',
            apiName: 'showLoading',
            path: '/showLoading'
        },
        {
            title: '存储',
            apiName: 'appStorage',
            path: '/appStorage'
        },
        {
            title: '获取标题栏高度',
            apiName: 'navHeight',
            path: '/navHeight'
        },
        {
            title: '获取App参数',
            apiName: 'getAppParams',
            path: '/getAppParams'
        },
        {
            title: '获取定位',
            apiName: 'getLocation',
            path: '/getLocation'
        },
        {
            title: '获取联系人',
            apiName: 'getContact',
            path: '/getContact'
        },
        {
            title: '读取剪切板内容',
            apiName: 'readPasteBoardContent',
            path: '/readPasteBoardContent'
        },
        {
            title: '复制到粘贴板并跳转',
            apiName: 'pasteBoardAndJump',
            path: '/pasteBoardAndJump'
        },
        {
            title: '获取推荐联系人',
            apiName: 'getRecommendContacts',
            path: '/getRecommendContacts'
        },
        {
            title: '个性化推荐配置',
            apiName: 'recommendSwitch',
            path: '/recommendSwitch'
        },
        {
            title: '获取相册（GetAlbum）',
            apiName: 'getAlbum',
            path: '/getAlbum'
        },
        {
            title: '获取相册（GetAlbumPlus）',
            apiName: 'getAlbumPlus',
            path: '/getAlbumPlus'
        },
        {
            title: '上传图片（UploadImage）',
            apiName: 'uploadImage',
            path: '/uploadImage'
        },
        {
            title: '上传文件（UploadFile）',
            apiName: 'uploadFile',
            path: '/uploadFile'
        },
        {
            title: '日历（Calendar）',
            apiName: 'calendar',
            path: '/calendar'
        }
        
    ])

    const navigate = useNavigate()
    useEffect(() => {
        if (device.mpaas) {
            document.addEventListener('back', () => {
                if (window.history.state.idx === 0) {
                    window.AlipayJSBridge.call('popWindow')
                } else {
                    window.history.back()
                }
            })
        }
    }, [])


    const handleItemClick = (item) => {
        if (navigate) {
            navigate(item.path)
        }
    }

    return (
        <div>
            <List mode='card'>
                {apiList.map(api => {
                    return (<List.Item style={{ textAlign: 'left' }}
                        key={api.title}
                        description={api.apiName}
                        clickable
                        onClick={() => { handleItemClick(api) }}>
                        {api.title}
                    </List.Item>)
                })}
            </List>
        </div>

    )
}

export default ApiTest