import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

/**
 * 获取推荐联系人
 */
const GetRecommendContacts = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            'contactsCount': 3
        }
        setApiParams(data)
        fcs.openModule('getRecommendContacts', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>GetRecommendContacts</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>获取推荐联系人</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default GetRecommendContacts