import { React, useState } from 'react'
import { Button, Divider, Space, Checkbox, Input } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const UpdateNavBar = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [navBarHidden, setNavBarHidden] = useState(false)
    const [richText, setRichText] = useState(false)
    const [disableGoBack, setDisableGoBack] = useState(false)
    const [hiddenBottomLine, setHiddenBottomLine] = useState(false)
    const [rightText, setRightText] = useState(false)
    const [rightImage, setRightImage] = useState(false)
    const [rightRichText, setRightRichText] = useState(false)

    const [title, setTitle] = useState('这是标题')
    const [bgColor, setBgColor] = useState('#FFFFFF')
    const [menuText, setMenuText] = useState('在线客服')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handle = () => {

        let data = {
            navBarHidden: navBarHidden,
            title: title,
            disableGoBack: disableGoBack,
            hiddenBottomLine: hiddenBottomLine,
            titleBarBackgroundColor: bgColor
        }
        if (richText) {
            data['title'] = '<font color="#ff0000" size=16>富文本标题</font>' 
        }
        if (rightText && rightImage) {
            data['rightBarItemList'] = [{
                label: menuText
            },
            {
                imageUrl: 'http://pic.alipayobjects.com/e/201212/1ntOVeWwtg.png'
            }]
        } else if (rightText) {
            data['rightBarItem'] = {
                label: menuText
            }
        } else if (rightImage) {
            data['rightBarItem'] = {
                imageUrl: 'http://pic.alipayobjects.com/e/201212/1ntOVeWwtg.png'
            }
        } else if (rightRichText) {
            data['rightBarItem'] = {
                label: "<font color='#ff0000' size=16>富文本</font>"
            }
        }
        data['backHandler'] = () => {
            fcs.invoke('showToast', '返回点击了')
        }
        if (window.dmlife) {
            window.dmlife.onRightBarItemTouched = (index) => {
                fcs.invoke('showToast', '右侧点击了' + index)

            }
        }
        setApiParams(data)
        fcs.invoke('updateNavBar', data, (resp) => {
            handleNormalCallback(resp)
        })
    }
    
    const richTextChange = (e) => {
        setRichText(e)
    }
    const navBarHiddenChange = (e) => {
        setNavBarHidden(e)
    }

    const disableGoBackChange = (e) => {
        setDisableGoBack(e)
    }

    const hiddenBottomLineChange = (e) => {
        setHiddenBottomLine(e)
    }
    const rightTextChange = (e) => {
        setRightText(e)
    }
    const rightImageChange = (e) => {
        setRightImage(e)
    }
    const rightRichTextChange = (e) => {
        setRightRichText(e)
    }
    return (
        <div>
            <Divider>UpdateNavBar</Divider>
            <Space wrap style={{ margin: '10px' }} direction='vertical' block>
                <Checkbox block checked={richText} onChange={richTextChange} key={'richText'}>富文本标题</Checkbox>
                <Checkbox block checked={navBarHidden} onChange={navBarHiddenChange} key={'navBarHidden'}>隐藏导航栏</Checkbox>
                <Checkbox block checked={disableGoBack} onChange={disableGoBackChange} key={'disableGoBack'}>禁止返回</Checkbox>
                <Checkbox block checked={hiddenBottomLine} onChange={hiddenBottomLineChange} key={'hiddenBottomLine'}>隐藏标题栏底部线条</Checkbox>
                <Checkbox block checked={rightText} onChange={rightTextChange} key={'rightText'}>右侧菜单-文字</Checkbox>
                <Checkbox block checked={rightImage} onChange={rightImageChange} key={'rightImage'}>右侧菜单-图片</Checkbox>
                <Checkbox block checked={rightRichText} onChange={rightRichTextChange} key={'rightRichText'}>右侧菜单-富文本</Checkbox>
            </Space>
            <Divider>标题文案</Divider>
            <Input style={{ margin: "10px", }}
                placeholder='请输入标题'
                value={title}
                onChange={val => {
                    setTitle(val)
                }}
            />
            <Divider>标题栏背景色</Divider>
            <Input style={{ margin: "10px", }}
                placeholder='请输入标题栏背景色'
                value={bgColor}
                onChange={val => {
                    setBgColor(val)
                }}
            />
            <Divider>右侧按钮文字</Divider>
            <Input style={{ margin: "10px", }}
                placeholder='请输入右侧按钮文字'
                value={menuText}
                onChange={val => {
                    setMenuText(val)
                }}
            />
            <Button color='primary' onClick={handle}>更新标题栏</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default UpdateNavBar