import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const BackIntercept = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});

    const handleNormalCallback = (resp) => {
        setResult("退出点击了")
    }

    const setBackIntercept = () => {
        let data = { value: '退出拦截已设置' }
        setApiParams(data)
        fcs.invoke('setCustomBackButtonWebHandler', {}, (resp) => {
            console.log('huidiaole')
            handleNormalCallback(resp)
        })
    }

    const setBackInterceptToast = () => {
        let data = { handlerName: 'showToast', params: { message: '退出提示Toast' } }
        fcs.invoke('setCustomBackButtonNativeHandler', data.handlerName, data.params)
    }
    return (
        <div>
            <Divider>backIntercept</Divider>
            <Button color='primary' onClick={setBackIntercept}>设置退出拦截</Button>
            <Divider>backIntercept</Divider>
            <Button color='primary' onClick={setBackInterceptToast}>设置退出弹窗Toast</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default BackIntercept