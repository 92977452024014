import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

/**
 * 复制到粘贴板并跳转	
 */
const PasteBoardAndJump = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            'pasteText': '这是需要赋值的文案',
            'jumpUrl': 'https://www.baidu.com',
            'toastText': '复制成功，准备跳转百度'
        }
        setApiParams(data)
        fcs.openModule('pasteBoardAndJump', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>PasteBoardAndJump</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>复制到粘贴板并跳转	</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default PasteBoardAndJump