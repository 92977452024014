import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import MyTree from './pages/MyTree'
import { NoticeBar } from 'antd-mobile'
import { device } from './utils/device'
import ApiTest from './pages/ApiTest'
import HttpRequest from './pages/HttpRequest'
import UserInfo from './pages/UserInfo'
import DeviceInfo from './pages/DeviceInfo'
import ClientConfig from './pages/ClientConfig'
import BankCardDetect from './pages/BankCardDetect'
import LocalIdHttpRequest from './pages/LocalIdHttpRequest'
import IDSubmitHttpRequest from './pages/IDSubmitHttpRequest'
import IdCardIdentification from './pages/IdCardIdentification'
import AppNotify from './pages/AppNotify'
import GotoApplicationSettings from './pages/GotoApplicationSettings'
import VerifyCurrentMobile from './pages/VerifyCurrentMobile'
import PermissionStatus from './pages/PermissionStatus'
import ShowLoading from './pages/ShowLoading'
import ShowToast from './pages/ShowToast'
import AppStorage from './pages/AppStorage'
import NavHeight from './pages/NavHeight'
import GetAppParams from './pages/GetAppParams'
import GetLocation from './pages/GetLocation'
import GetContact from './pages/GetContact'
import ReadPasteBoardContent from './pages/ReadPasteBoardContent'
import PasteBoardAndJump from './pages/PasteBoardAndJump'
import GetRecommendContacts from './pages/GetRecommendContacts'
import UpdateNavBar from './pages/UpdateNavBar'
import GetAlbum from './pages/GetAlbum'
import GetAlbumPlus from './pages/GetAlbumPlus'
import UploadImage from './pages/UploadImage.jsx'
import UploadFile from './pages/UploadFile.jsx'
import CalendarApi from './pages/calendar.jsx'
import InterceptPageClose from './pages/InterceptPageClose'
import RegisterNotificationAuthorization from './pages/RegisterNotificationAuthorization'
import FormHtml from './pages/FormHtml'
import TrackConfig from './pages/TrackConfig'
import DataCollect from './pages/DataCollect'
import RecommendSwitch from './pages/RecommendSwitch'
import BackIntercept from './pages/BackIntercept.jsx'
import OTOCall from './pages/OTOCall.jsx'

const App = () => {

  const envName = () => {
    var name = '未知环境'
    if (device.dmlife) {
      name = '当前为 dmlife 容器'
    } else if (device.mpaas) {
      name = '当前为 mPaaS 容器'
    }
    return name
  }
  return (
    <div className="App">
      {"未知环境" === envName() && <MyTree />}
      {"未知环境" !== envName() &&
        <><NoticeBar content={envName()} color='info' /><BrowserRouter>
          <Routes>
            <Route path='/' element={<ApiTest />} />
            <Route path='/httpRequest' element={<HttpRequest />} />
            <Route path='/getUserInfo' element={<UserInfo />} />
            <Route path='/getDeviceInfo' element={<DeviceInfo />} />
            <Route path='/clientConfig' element={<ClientConfig />} />
            <Route path='/bankCardDetect' element={<BankCardDetect />} />
            <Route path='/localIdHttpRequest' element={<LocalIdHttpRequest />} />
            <Route path='/IDSubmitHttpRequest' element={<IDSubmitHttpRequest />} />
            <Route path='/idCardIdentification' element={<IdCardIdentification />} />
            <Route path='/appNotify' element={<AppNotify />} />
            <Route path='/verifyCurrentMobile' element={<VerifyCurrentMobile />} />
            <Route path='/gotoApplicationSettings' element={<GotoApplicationSettings />} />
            <Route path='/permissionStatus' element={<PermissionStatus />} />
            <Route path='/showLoading' element={<ShowLoading />} />
            <Route path='/showToast' element={<ShowToast />} />
            <Route path='/appStorage' element={<AppStorage />} />
            <Route path='/navHeight' element={<NavHeight />} />
            <Route path='/getAppParams' element={<GetAppParams />} />
            <Route path='/getLocation' element={<GetLocation />} />
            <Route path='/getContact' element={<GetContact />} />
            <Route path='/readPasteBoardContent' element={<ReadPasteBoardContent />} />
            <Route path='/pasteBoardAndJump' element={<PasteBoardAndJump />} />
            <Route path='/getRecommendContacts' element={<GetRecommendContacts />} />
            <Route path='/updateNavBar' element={<UpdateNavBar />} />
            <Route path='/interceptPageClose' element={<InterceptPageClose />} />
            <Route path='/registerNotificationAuthorization' element={<RegisterNotificationAuthorization />} />
            <Route path='/formHtml' element={<FormHtml />} />
            <Route path='/trackConfig' element={<TrackConfig />} />
            <Route path='/dataCollect' element={<DataCollect />} />
            <Route path='/GetAlbum' element={<GetAlbum />} />
            <Route path='/GetAlbumPlus' element={<GetAlbumPlus />} />
            <Route path='/uploadImage' element={<UploadImage />} />
            <Route path='/uploadFile' element={<UploadFile />} />
            <Route path='/calendar' element={<CalendarApi />} />
            <Route path='/recommendSwitch' element={<RecommendSwitch />} />
            <Route path='/backIntercept' element={<BackIntercept />} />
            <Route path='/OTOCall' element={<OTOCall />} />

          </Routes>
        </BrowserRouter></>
      }
    </div>

  )
}

export default App
