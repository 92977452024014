import { React, useEffect, useState } from 'react';
import { Divider } from 'antd-mobile';

const ApiResult = (props) => {
    const { result, apiParams} = props
    const [resultText, setResultText] = useState('{}')
    const [apiParamsText, setApiParamsText] = useState('{}')

    useEffect(()=>{
        setResultText(JSON.stringify(result, null, 2))
        setApiParamsText(JSON.stringify(apiParams, null, 2))
    }, [result, apiParams])

    return (
        <div>
            <Divider>返回结果</Divider>
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: 13, wordBreak: 'break-word', textAlign: 'left' }}>{resultText}</pre>
            <Divider>Api调用参数</Divider>
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: 13, wordBreak: 'break-word', textAlign: 'left' }}>{apiParamsText}</pre>
        </div>
    )
}

export default ApiResult