import { React, useRef, useState } from 'react';
import { Button, Divider, Space, Radio } from 'antd-mobile';
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const AppNotify = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({})
    const [currentName, setCurrentName] = useState('RECOMMEND_SWITCH')
    const recommendSwitch = useRef(false)
    const biometricSwitch = useRef(false)

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleAppNotify = () => {
        var params = {}
        switch (currentName) {
            case 'DATA_COLLECT':
                params = {
                    event: 'userInfoEntry',
                    behaviorId: 'xxxbehaviorIdxxx'
                }
                break;
            case 'RECOMMEND_SWITCH':
                params = {
                    recommendSwitch: recommendSwitch.current ? 'on' : 'off'
                }
                recommendSwitch.current = !recommendSwitch.current
                break;

            case 'BIOMETRIC_SWITCH':
                params = {
                    biometricSwitch: biometricSwitch.current
                }
                biometricSwitch.current = !biometricSwitch.current
                break;
            case 'CMS_EXIT_POPUP':

                break;
            default:
                break;
        }
        let data = { name: currentName, params: params }
        setResult({})
        setApiParams(data)
        fcs.invoke('appNotify', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    const handleNameChange = (e) => {
        setCurrentName(e)
    }

    return (
        <div>
            <Divider>appNotify</Divider>
            <p>事件类型选择</p>
            <Radio.Group onChange={handleNameChange} value={currentName}>
                <Space direction='vertical' style={{ margin: '10px' }} block>
                    <Radio block value={'DATA_COLLECT'}>DATA_COLLECT</Radio>
                    <Radio block value={'RECOMMEND_SWITCH'}>RECOMMEND_SWITCH</Radio>
                    <Radio block value={'BIOMETRIC_SWITCH'}>BIOMETRIC_SWITCH</Radio>
                    <Radio block value={'CMS_EXIT_POPUP'}>CMS_EXIT_POPUP</Radio>
                </Space>
            </Radio.Group>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <Button color='primary' onClick={handleAppNotify} >事件通知</Button>
            </Space>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default AppNotify