import { React, useRef, useState } from 'react'
import { Button, Divider, Space, Checkbox } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const InterceptPageClose = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [intercept, setIntercept] = useState(false)

    const interceptValue = useRef(false)

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }
    const handleInterceptChange = (e) => {
        setIntercept(e)
        interceptValue.current = e
    }
    const handleIntercept = () => {
        let data = { intercept: interceptValue.current }
        setApiParams(data)
        fcs.invoke('interceptPageClose', data, (resp) => {
            handleNormalCallback(resp)
        })
    }
    return (
        <div>
            <Divider>InterceptPageClose</Divider>

            <Space wrap style={{ margin: '10px' }} block>
                <Checkbox block checked={intercept} onChange={handleInterceptChange} key={'interceptChange'}>是否拦截页面关闭</Checkbox>
            </Space>
            <Button color='primary' onClick={handleIntercept}>设置拦截状态</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default InterceptPageClose