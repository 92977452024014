import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

/**
 * 获取App参数
 */
const GetAppParams = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            keys: [
                'sessionBeginEventId'
            ]
        }
        setApiParams(data)
        fcs.openModule('getAppParams', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>GetAppParams</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>获取App参数</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default GetAppParams