
// 标识除去历史的遗留, 新增都使用小写
export function Device(userAgent = '') {
  const ua = userAgent || window?.navigator?.userAgent;
  const device = {};
  const isApp = /AppChannel\//gi.test(ua);
  const isMPaaSApp = /Container\/mPaaSClient/gi.test(ua);

  const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
  const iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
  const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/);

  // 标识都使用小写, 多个单词使用小驼峰
  if ((iphone && !ipod) || ipad || ipod) {
    device.ios = true;
  }

  if (android) {
    device.android = true;
  }

  if (isApp) {
    // UA 相关 http://wiki.caijj.net/pages/viewpage.action?pageId=159122296
    const appNameMatch = ua.match(/AppChannel\/([a-z_]+)/i);
    const appVersionMatch = ua.match(/SHApp\/([\d.]+)/);
    const appName = appNameMatch && appNameMatch[1];
    const appVersion = appVersionMatch && appVersionMatch[1];

    const appInnerVersionMatch = ua.match(/SHAppInner\/([\d.]+)/);
    const innerVersion = appInnerVersionMatch && appInnerVersionMatch[1];

    device.appName = appName || undefined;
    device.appVersion = appVersion || undefined; // 还呗 5.12.0 后支持的新ua
    device.innerVersion = innerVersion || undefined; //  // 还呗 5.12.0 后支持的新ua

    device.isInApp = true;
    if (isMPaaSApp) {
      device.mpaas = true;
    } else {
      device.lowerApp = true;
      device.dmlife = true;
    }
  }
  return device;
}

export const device = Device();

export default device;
