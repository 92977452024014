import { React, useState } from 'react'
import { Button, Divider, Space, Radio, Input } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const ClientConfig = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})
    const [configName, setConfigName] = useState('we_chat_login_dialog_config')
    const [configType, setConfigType] = useState('Map')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleClientConfig = () => {
        let data = {
            configKey: configName,
            dataType: configType
        }
        setApiParams(data)
        fcs.invoke('clientConfig', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    const handleConfigTypeChange = (e) => {
        setConfigType(e)
    }

    return (
        <div>
            <Divider>clientConfig</Divider>
            <Input style={{ margin: "10px", }}
                placeholder='请输入配置名称'
                value={configName}
                onChange={val => {
                    setConfigName(val)
                }}
            />
            <p>配置类型选择</p>
            <Radio.Group onChange={handleConfigTypeChange} value={configType}>
                <Space direction='vertical' style={{ margin: '10px' }} block>
                    <Radio block value={'Int'}>Int</Radio>
                    <Radio block value={'String'}>String</Radio>
                    <Radio block value={'Boolean'}>Boolean</Radio>
                    <Radio block value={'Long'}>Long</Radio>
                    <Radio block value={'Map'}>Map</Radio>
                    <Radio block value={'List'}>List</Radio>
                </Space>

            </Radio.Group>
            <Button color='primary' onClick={handleClientConfig}>获取移动端配置</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default ClientConfig
