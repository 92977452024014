import { React } from 'react';

const IDSubmitHttpRequest = () => {
    return (
        <div>
            暂未实现
        </div>
    )
}

export default IDSubmitHttpRequest