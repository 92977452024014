import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult  from './ApiResult'
import { fcs } from '../utils/functions'

const UserInfo = () => {
    const [result, setResult] = useState({});
    const [apiParams] = useState({});

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleUserInfo = () => {
        fcs.invoke('getUserInfo', {}, (resp) => {
            handleNormalCallback(resp)
        })
    }
    return (
        <div>
            <Divider>getUserInfo</Divider>
            <Button color='primary' onClick={handleUserInfo}>获取用户信息</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default UserInfo