import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const BankCardDetect = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})


    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            currentSupplier: 'TC',
            tcSdkParamsUrl: '/hbapplycollector/supplier/liveness/tc/sdk/params'
        }
        setApiParams(data)
        fcs.openModule('bankCardDetect', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>bankCardDetect</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>银行卡识别</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default BankCardDetect