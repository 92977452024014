import { React, useState } from 'react'
import { Button, Divider, Space, Input } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const VerifyCurrentMobile = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [value, setValue] = useState('')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleVerifyCurrentMobile = () => {
        let data =  { value: value }
        setApiParams(data)
        fcs.invoke('verifyCurrentMobile', data, (resp) => {
            handleNormalCallback(resp)
        })
    }
    return (
        <div>
            <Divider>verifyCurrentMobile</Divider>
            <Input style={{ margin: "10px" ,}}
                placeholder='请输入手机号'
                value={value}
                onChange={val => {
                    setValue(val)
                }}
            />
            <Button color='primary' onClick={handleVerifyCurrentMobile}>校验手机号</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default VerifyCurrentMobile