import { React, useState } from 'react'
import { Button, Divider, Space, Input } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import { device } from '../utils/device'

const ShowLoading = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({});
    const [value, setValue] = useState('请输入文案')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleShowLoading = () => {
        if (device.dmlife) {
            setApiParams(value)
            fcs.invoke('showLoadingActivity', value, (resp) => {
                handleNormalCallback(resp)
            })
            setTimeout(()=>{
                fcs.invoke('hideLoadingActivity',{}, (resp) => {
                })
            }, 5000)
        } else {
            let data = {show: true, loadingTitle: value}
            setApiParams(data)
            fcs.invoke('updateLoadingActivity', data, (resp) => {
                handleNormalCallback(resp)
            })
            setTimeout(()=>{
                fcs.invoke('updateLoadingActivity',{show: false}, (resp) => {
                })
            }, 5000)
        }
    }
    return (
        <div>
            <Divider>showLoading - 5 秒会消失</Divider>
            <Input style={{ margin: "10px" ,}}
                placeholder='请输入Loading文案'
                value={value}
                onChange={val => {
                    setValue(val)
                }}
            />
            <Button color='primary' onClick={handleShowLoading}>展示Loading</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default ShowLoading