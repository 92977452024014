import { React, useState } from 'react'
import { Button, Divider, Space } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import { Col, Row } from 'antd'

const RecommendSwitch = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const clickHandler = (name) => {
        let data = {}
        
        if (name === 'RECOMMEND_SWITCH') {
            data.recommendSwitch = 'on';
        } else if (name === 'BIOMETRIC_SWITCH') {
            data.biometricSwitch = true;
        } else if (name === 'getContactAndCallLogStatus') {

        } else if (name === 'track1') {
            data = {
                isNewTrack: true,
                priority: 1,
                trackObj: {}
            };
            setApiParams(data);
            fcs.invoke('track', data, (resp) => {
                handleNormalCallback(resp);
            });
            return;
        } else if (name === 'track2') {
            data = {
                isNewTrack: true,
                priority: 1,
                trackObj: [{}]
            };
            setApiParams(data);
            fcs.invoke('track', data, (resp) => {
                handleNormalCallback(resp);
            });
            return;
        }
        
        setApiParams(data)
        fcs.openModule(name, data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Button color='primary' onClick={() => { window.history.back(); }}>goback</Button>        
            <Divider>PasteBoardAndJump</Divider>
            <Row gutter={[12, 12]} style={{marginLeft: '12px'}}>
                <Col>
                    <Button color='primary' onClick={() => clickHandler('RECOMMEND_SWITCH')}>个性化推荐开关</Button>        
                </Col>
                <Col>
                    <Button color='primary' onClick={() => clickHandler('BIOMETRIC_SWITCH')}>生物识别开关</Button>
                </Col>
                <Col>
                    <Button color='primary' onClick={() => clickHandler('getContactAndCallLogStatus')}>通讯录权限状态</Button>
                </Col>
                <Col>
                    <Button color='primary' onClick={() => clickHandler('track1')}>单条埋点</Button>
                </Col>
                <Col>
                    <Button color='primary' onClick={() => clickHandler('track2')}>批量埋点</Button>
                </Col>
            </Row>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default RecommendSwitch