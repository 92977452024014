import { React, useState } from 'react';
import { Button, Divider, Space, Radio } from 'antd-mobile';
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const PermissionStatus = () => {
    const [result, setResult] = useState({});
    const [apiParams, setApiParams] = useState({})
    const [currentName, setCurrentName] = useState('PERMISSION_CAMERA')

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handlePermissionStatus = () => {        
        let data = { permissionKey: currentName }
        setResult({})
        setApiParams(data)
        fcs.invoke('permissionStatus', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    const handleNameChange = (e) => {
        setCurrentName(e)
    }

    return (
        <div>
            <Divider>permissionStatus</Divider>
            <p>权限类型选择</p>
            <Radio.Group onChange={handleNameChange} value={currentName}>
                <Space direction='vertical' style={{ margin: '10px' }} block>
                    <Radio block value={'PERMISSION_CAMERA'}>PERMISSION_CAMERA</Radio>
                    <Radio block value={'PERMISSION_CONTACTS'}>PERMISSION_CONTACTS</Radio>
                    <Radio block value={'PERMISSION_LOCATION'}>PERMISSION_LOCATION</Radio>
                    <Radio block value={'PERMISSION_STORAGE'}>PERMISSION_STORAGE</Radio>
                    <Radio block value={'PERMISSION_CALL_LOG'}>PERMISSION_CALL_LOG</Radio>
                    <Radio block value={'PERMISSION_PHONE_STATE'}>PERMISSION_PHONE_STATE</Radio>
                    <Radio block value={'PERMISSION_SMS'}>PERMISSION_SMS</Radio>
                    <Radio block value={'PERMISSION_PHOTO_LIBRARY'}>PERMISSION_PHOTO_LIBRARY</Radio>
                    <Radio block value={'PERMISSION_NOTIFICATION'}>PERMISSION_NOTIFICATION</Radio>
                    <Radio block value={'PERMISSION_BIOMETRIC'}>PERMISSION_BIOMETRIC</Radio>
                </Space>
            </Radio.Group>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <Button color='primary' onClick={handlePermissionStatus} >获取权限状态</Button>
            </Space>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default PermissionStatus