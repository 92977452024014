import { React, useRef, useState } from 'react'
import { Button, Divider, Checkbox, Space } from 'antd-mobile'
import ApiResult  from './ApiResult'
import { fcs } from '../utils/functions'

const HttpRequest = () => {
    const [showLoading, setShowLoading] = useState(false)
    const [resendAfterLoginBack, setResendAfterLoginBack] = useState(false)
    const [disableAutoLogin, setDisableAutoLogin] = useState(false)
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const showLoadingValue = useRef(false)
    const resendAfterLoginBackValue = useRef(false)
    const disableAutoLoginValue = useRef(false)

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleNetwork = (type) => {
        let data = {
            url: 'http://daixc.com/aabbcc',
            params: {},
            options: {
                showLoading: showLoadingValue.current,
                loadingTitle: 'Loading测试',
                resendAfterLoginBack: resendAfterLoginBackValue.current,
                disableAutoLogin: disableAutoLoginValue.current
            },
            method: type
        }
        setApiParams(data)
        fcs.invoke('httpRequest', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    const showLoadingChange = (e) => {
        setShowLoading(e)
        showLoadingValue.current = e
    }

    const resendAfterLoginBackChange = (e) => {
        setResendAfterLoginBack(e)
        resendAfterLoginBackValue.current = e
    }

    const disableAutoLoginChange = (e) => {
        setDisableAutoLogin(e)
        disableAutoLoginValue.current = e
    }

    return (
        <div>
            <Divider>httpRequest</Divider>
            <Space wrap style={{ margin: '10px' }} direction='vertical' block>
                <Checkbox block checked={showLoading} onChange={showLoadingChange} key={'showLoadingChange'}>请求时展示Loading</Checkbox>
                <Checkbox block checked={disableAutoLogin} onChange={disableAutoLoginChange} key={'disableAutoLoginChange'}>禁止自动跳转登录</Checkbox>
                <Checkbox block checked={resendAfterLoginBack} onChange={resendAfterLoginBackChange} key={'resendAfterLoginBackChange'}>登录成功后再次请求</Checkbox>
            </Space>

            <Space style={{ margin: '10px' }}>
                <Button color='primary' onClick={() => { handleNetwork('POST') }} >POST</Button>
                <Button color='primary' onClick={() => { handleNetwork('GET') }} >GET</Button>
                <Button color='primary' onClick={() => { handleNetwork('PUT') }} >PUT</Button>
                <Button color='primary' onClick={() => { handleNetwork('DELETE') }} >DELETE</Button>
            </Space>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default HttpRequest